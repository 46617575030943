@font-face {
  font-family: 'Yellix';
  src: url(./fonts/YellixTRIAL-Regular.8623a84c.otf);
}

@font-face {
  font-family: 'Authentic Sans';
  src: url(./fonts/AUTHENTICSans-90.woff);
}


* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  font-family: sans-serif;
  font-size: 1.25rem;
  letter-spacing: .3px;
  width: 100%;
  scroll-behavior: smooth;
  font-family: 'Yellix', Arial, Helvetica, sans-serif;
}

html {
  overflow-x: hidden;
}

/* OVERLAY */

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: radial-gradient(60.77% 56.77% at 61.47% 30.92%, rgba(165, 255, 185, 0.126) 65.1%, rgba(2, 51, 228, 0.052) 81.77%, rgba(255, 0, 15, 0.042) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
  linear-gradient(180.09deg, rgba(255, 255, 255, 0.54) 70.33%, #FFFFFF 91.36%, #9D24D6 112.37%),
  linear-gradient(0deg, #FFFFFF, #FFFFFF);
  opacity: 95%;
  z-index: -1000;
}

/* PROMPTS */

.wrapper {
  margin: 25px;
  margin-top: 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.prompt-list {
  display: flex;
  /* flex-direction: column;   */
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
  line-height: 150%;
  align-items: center;
  justify-content: center;
  gap: 8px;
  filter: saturate(1.25);

}

.prompt {
  color: mediumorchid;
  padding: 8px 24px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 75%;
  /* font-size: 1.75rem; */
  font-size: 1rem;
  line-height: 125%;
  letter-spacing: .1px;
  border: 1.5px dashed rgba(234, 203, 241, 0.78);
  fill: white;
  border-radius: 10px;
  background: linear-gradient(89.81deg, rgba(218, 112, 214, 0.15) 0.13%, rgba(218, 112, 214, 0) 58.88%, rgba(218, 112, 214, 0.15) 90.63%);
}

.prompt-text {
  justify-content: center;
}

/* .prompt:hover {
  border: 2px dashed mediumorchid;
  background: rgba(186, 85, 211, 0.065);
  border-radius: 100px;
} */

.single-prompt {
  display: flex;
  justify-content: center;
  filter: saturate(1.25);
}

/* INFO */

a {
  color: mediumorchid;
  text-decoration: underline;
  text-decoration-color: mediumorchid;
  transition: text-decoration-color .2s;
}

a:hover {
  cursor: pointer;
  text-decoration-color: aquamarine;
}

.title {
  text-decoration: none;
  border: 1px solid mediumorchid;
  padding: 8px 12px;
  border-radius: 100px;
  background-color: white;
  filter: drop-shadow(0 0 20px rgba(186, 85, 211, 0.25));
}


.header {
  width: 100%;
  background: linear-gradient(180deg, #fffffff0 40%, #ffffff6b 68.49%, rgba(255, 255, 255, 0) 100%);
  position: fixed;
  top: 0;
  height: 50px;
  text-align: center;
  padding: 24px 0px;
  text-transform: uppercase;
  font-size: .75rem;
}

div.about, div.choose {
  color: mediumorchid;
  padding: 8px 16px;
  position: fixed;
  font-size: 1rem;
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  text-transform: uppercase;
  fill: none;
  /* z-index: 1000; */
  transition: transform .3s;
  filter: saturate(1.25);
}

div.about:hover, div.choose:hover {
  cursor: pointer;
}

div.about {
  top:50%;
  right: 15%;
  transform: rotate(10deg)
}

div.about:hover{
  transform: rotate(-8deg);
}

div.choose {
  top: 35%;
  left: 15%;
  transform: rotate(-8deg)
}

div.choose:hover{
  transform: rotate(10deg);
}

.label {
  background-color: white;
  border-radius: 100px;
  padding: 4px 12px;
  border: 1px solid orchid;
}


.list {
  margin: 16px 0;
}

.list-item {
  display: flex;
  align-items: top;
  margin-bottom: 16px;
  line-height: 150%;
  width: 35vw;
}

.year {
  font-size: .675rem;
  font-style: italic;
  padding-right: 12px;
  display: inline;
  width: 70px;
  flex-shrink: 0;
  /* text-align: right; */
}

.credits {
  font-size: .6rem;
  margin-top: 24px;
}

.intro-modal {
   background: radial-gradient(64.05% 64.05% at 36.01% 35.95%, rgba(131, 181, 142, 0.126) 65.1%, rgba(0, 26, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
  linear-gradient(180.09deg, rgba(255, 255, 255, 0.54) 70.33%, #ffffff89 85.36%, mediumorchid 112.37%),
  linear-gradient(0deg, #ffffffbe, #ffffffd6);    
    border: 1px solid mediumorchid;
    width: 500px;
    position: fixed;
    top: 20%;
    display: flex;
    justify-content: center;
    /* align-items: center; */
    flex-direction: column;
    color: mediumorchid;
    padding: 40px;
    font-size: .75rem;
    line-height: 150%;
    z-index: 1000;
    /* border-radius: 20px; */
    filter: saturate(1.25);
}

.close-modal {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.close-modal > button {
  font-family: inherit;
  color: inherit;
  border: 1px dashed orchid;
  background: none;
  padding: 4px 12px;
  border-radius: 100px;
  font-size: .875rem;
}

.close-modal > button:hover {
  cursor: pointer;
}

.hide {
  display: none;
}

#top {
  position: absolute;
  top: 0;
}

/* LOADING  */

.loading {
  color: mediumorchid;
  font-style: italic;
  /* border: 1px dashed mediumorchid; */
  border: none;
  padding: 8px 16px;
  font-size: .75rem;
  margin-bottom: 24px;
  background: none;
}

.load-more {
  color: mediumorchid;
  border: 1px dashed mediumorchid;
  padding: 8px 16px;
  font-size: .75rem;
  margin-bottom: 24px;
  background: none;
  position: relative;
  border-radius: 100px;
}

.load-more:hover {
  cursor: pointer;
}

.loading-button {
  margin: 48px;
  filter: saturate(1.25);
} 

/* SCROLLBAR */

/* width */
::-webkit-scrollbar {
  width: 8px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(186, 85, 211, 0.8); 
  border-radius: 0px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: mediumorchid; 
}

.illo {
  filter: drop-shadow(0 0 20px rgba(186, 85, 211, 0.25));
  width: 150px;
  fill: none;
  transition: box-shadow .2s;
}


/* MOBILE */

@media only screen and (max-width: 1000px) {
  div.about{
    right: 5%;
  }
  
  div.choose {
   left: 5%;
  }
   
} 

@media only screen and (max-width: 800px) {
.intro-modal {
  width: 80vw;
}
   
} 

.psa {
  display: none;
}

@media only screen and (max-width: 500px) {

  .overlay {
      background: none;
  }

  html {
    overflow-x: hidden;
    width: 100%;
  }

  .wrapper {
    margin: 0;
  }

  .prompt-list {
    margin-top: 60%;
  }

  .prompt {
    border-radius: 20px;
    border: none;
    /* background: none; */
    border-bottom: 1px dashed rgba(186, 85, 211, 0.4);
  }

  .prompt-text {
    width: 80vw;
    font-size: 1.25rem;
  }

  .intro-modal {
    width: 95%;
    top: 20%;
    left: 2%;
    /* background: rgba(255, 255, 255, .95); */
    color: mediumorchid;
    font-size: .75rem;
    border-radius: 0;
    padding: 12px;
  }

  .intro-modal > p {
    margin: 0 0 16px 0;
  }

  .close-modal {
    margin-top: 0;
  }

  .list {
    margin: 0;
  }

  .list-item {
    width: auto;
  }

  .illo {
    width: 100px;
  }

  .single-prompt {
    margin-top: 60%;
  }

  .header {
    height: 300px;
    width: 100%;
    padding-top: 8px;
  }

  div.about, div.choose {
    font-size: .75rem;
  }

  div.about {
    top: 48px;
    right: 8px;
  }

  div.choose {
    top: 24px;
    /* top: auto; */
    left:0px;
  }

  .title {
    border: none;
    background: none;
  }

}

